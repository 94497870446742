<template>
  <a-card :bordered="false">
    <a-form :label-col="{ span: 2 }" >
      <a-form-item label="设备号" required :wrapper-col="{ span: 10 }">
        <a-input v-model:value="imei" placeholder="请输入设备号"></a-input>
        <div class="s_c999" style="line-height: 1.5715!important;">
          <p>提示：</p>
          <p>1、设备号查看路径：采集器app启动时，长按中间的logo图片；</p>
          <p>2、重置设备可能会导致设备号发生变化。</p>
        </div>
      </a-form-item>
      <a-form-item label="关联品牌" required :wrapper-col="{ span: 18 }">
        <a-button type="primary" @click="brandVisible=true">添加品牌商</a-button>
        <a-table class="m-t-20" :pagination='false' :loading='listLoading' bordered rowKey='brand_no' :columns='columns' :data-source='data'></a-table>
      </a-form-item>
      <a-form-item :wrapper-col="{ span: 16, offset: 2 }">
        <a-button type="primary" :loading="loading" @click="comfirmCode">保存</a-button>
      </a-form-item>
    </a-form>
  </a-card>
  <!-- 品牌商弹窗 -->
  <a-modal v-model:visible="brandVisible" title="品牌商" :width='1200' destroyOnClose centered :footer="null">
    <selectBrand v-on:select="brandSelect"  :checkout="false" :selectList="data" />
  </a-modal>
</template>
<script>
import { ref, inject ,onMounted} from "vue";
import { message } from "ant-design-vue";
import { $iscode } from "@/utils/app";
import { InvisibleEdit,InvisibleAdd,invisibleDetail } from "@/api/pda.js";
export default {
  setup(props,{emit}) {
    // 列表数据和列头配置
    const data = ref([]);
    const imei = ref('');
    const loading = ref(false)
    let columns = [
      {
        title: "品牌商编号",
        dataIndex: "brand_no",
        key: "brand_no",
      },
      {
        title: "公司名称",
        dataIndex: "company",
        key: "company",
      },
      {
        title: "品牌商账号",
        dataIndex: "account",
        key: "account",
      },
    ];
    // 选中品牌商
    const brandVisible = ref(false);
    const brandSelect = (e) => {
      data.value = [e];
      brandVisible.value = false;
    };
    const $route = inject("$route");
    const $router = inject("$router");
    //获取详情
    const initData = async () => {
      const hide = message.loading("正在加载中...", 0);
      let res = await invisibleDetail( $route.query.id ).then((res) => res.data);
      if ($iscode(res)) {
        imei.value = res.data.imei;
        data.value = [res.data.brand_info]
      } else {
        message.error(res.msg);
      }
      setTimeout(hide);
    };
    // 确认创建
    const comfirmCode = async () => {
      if (!imei.value) {
        message.error("请输入设备号！");
        return;
      }
      if (!data.value.length) {
        message.error("请选择品牌商！");
        return;
      }
      loading.value = true;
      let result;
      if ($route.query.type == 'add') {
        result = await InvisibleAdd({ imei:imei.value,brand_id: data.value[0].brand_no}).then(res => res.data).catch(error => error)
      }else{
        result = await InvisibleEdit({ imei:imei.value,brand_id: data.value[0].brand_no,id: $route.query.id }).then(res => res.data).catch(error => error)
      }
      loading.value = false;
      if ($iscode(result)) {
        message.success(result.msg);
        setTimeout(() => {
           $router.go(-1)
        }, 1000)
      }else{
        message.error(result.msg);
      }
    };
    onMounted(async () => {
      if ($route.query.type == 'edit') {
        initData();
      }
    });
    return {
      data,
      columns,
      brandSelect,
      brandVisible,
      comfirmCode,
      imei,
      loading
    };
  },
};
</script>